import React from 'react';
import './App.css';

function App() {
  // Sample image URLs - replace these with your actual image URLs
  const imageUrls = [
    "/cakes/image_1.jpg",
    "/cakes/image_2.jpg",
    "/cakes/image_3.jpg",
    "/cakes/image_4.jpg",
    "/cakes/image_5.jpg",
    "/cakes/image_6.jpg",
    "/cakes/image_7.jpg",
    "/cakes/image_8.jpg",
    "/cakes/image_9.jpg"
  ];

  return (
    <div className="App">
      <header className="page-header">
        <h1><img id="main_icon" alt="" src="../sem_fundo.png"/></h1>
        <a
          href="https://www.facebook.com/tudoovo"
          target="_blank"
          rel="noreferrer noopener"
          className="facebook-link"
        >
          Faça já a sua encomenda no Facebook
        </a>
      </header>
      <div className="image-grid">
        {imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt=""
            className="grid-image"
          />
        ))}
      </div>
    </div>
  );
}

export default App;
